.document-container {
  box-sizing: border-box;
  border: 1px solid #eee;
}

/* .print-container {
  padding: 2rem;
} */

@page {
  size: A4;
  /* margin-top: 1rem; */
}

@media print {
  html,
  body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }

  .document-container {
    border: none;
  }

  .print-container {
    padding: 0 !important;
    margin: 0 !important;
    gap: 0 !important;
  }

  .hide-bg-on-print {
    background-image: none !important;
  }
}
