.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.print-label-border-radius .print-label-each .label-sn {
  font-size: 0.8rem;
}

.info-item {
  margin-bottom: 5px;
}

.print-label-wrapper .row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  margin-bottom: 0;
}

/* CUSTOM DAYUN */
.template_custom_dayun {
  font-size: 0.7rem;
}

.template_custom_dayun .print-label-each {
  padding-top: 1.7cm;
  padding-left: 0.3cm;
  padding-right: 0.3cm;
}

.template_custom_dayun .print-label-each .label-sn {
  font-size: 0.6rem;
}

.template_custom_dayun .print-label-each .info-item {
  margin-bottom: 0;
}

/* A4 แนวตั้ง (5 แถว แถวละ 2 ดวง) TEMPLATE */
.template_A4_1 {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2mm;
  box-sizing: border-box;
}

.template_A4_1 .row {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: "0";
  margin-bottom: "0";
}

.template_A4_1 .print-label-each {
  height: 5cm;
}

/* Label 10x5ซม. (แนวตั้ง) TEMPLATE */
.template_label_10x5_cm_1 {
  width: 5cm;
  box-sizing: border-box;
}

.template_label_10x5_cm_1 .row {
  transform: rotate(-90deg);
  height: 10cm;
  width: 10cm;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  margin-bottom: 0;
}

.template_label_10x5_cm_1 .print-label-each {
  height: 5cm;
}

/* Label 10x5ซม. (แนวนอน) TEMPLATE */
.template_label_10x5_cm_2 {
  width: 10cm;
  box-sizing: border-box;
}

.template_label_10x5_cm_2 .row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  margin-bottom: 0;
}

.template_label_10x5_cm_2 .print-label-each {
  height: 5cm;
}

.template_label_10x5_cm_2 .vertical {
  height: 10cm;
  width: 10cm;
}

/* Label DAYUN 7x4.7ซม. (แนวตั้ง) TEMPLATE */
.template_label_DAYUN_7x4_7_cm_1 {
  width: 4.7cm;
  box-sizing: border-box;
  font-size: 0.7rem;
}

.template_label_DAYUN_7x4_7_cm_1 .row {
  transform: rotate(-90deg);
  height: 7cm;
  width: 7cm;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  margin-bottom: 0;
}

.template_label_DAYUN_7x4_7_cm_1 .print-label-each {
  padding-top: 1.7cm;
  padding-left: 0.5cm;
  padding-right: 0.5cm;
  height: 4.7cm;
}

.template_label_DAYUN_7x4_7_cm_1 .print-label-each .label-sn {
  font-size: 0.6rem;
}

.template_label_DAYUN_7x4_7_cm_1 .print-label-each .info-item {
  margin-bottom: 0;
}

/* Label DAYUN 7x4.7ซม. (แนวนอน) TEMPLATE */
.template_label_DAYUN_7x4_7_cm_2 {
  width: 7cm;
  box-sizing: border-box;
  font-size: 0.7rem;
}

.template_label_DAYUN_7x4_7_cm_2 .row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  margin-bottom: 0;
}

.template_label_DAYUN_7x4_7_cm_2 .print-label-each {
  padding-top: 1.7cm;
  padding-left: 0.5cm;
  padding-right: 0.5cm;
  height: 4.7cm;
}

.template_label_DAYUN_7x4_7_cm_2 .print-label-each .label-sn {
  font-size: 0.6rem;
}

.template_label_DAYUN_7x4_7_cm_2 .print-label-each .info-item {
  margin-bottom: 0;
}

/* Label 6x4นิ้ว (แนวตั้ง) TEMPLATE */
.template_label_6x4_inch_1 {
  width: 4in;
  box-sizing: border-box;
}

.template_label_6x4_inch_1 .row {
  /* transform: rotate(-90deg);
  height: 6in;
  width: 6in;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  margin-bottom: 0; */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  margin-bottom: 0;
}

.template_label_6x4_inch_1 .print-label-each {
  flex-direction: column;
  height: 6in;
}

.template_label_6x4_inch_1 .print-label-each > div {
  margin: 4px;
}

/* Label 6x4นิ้ว (แนวนอน) TEMPLATE */
.template_label_6x4_inch_2 {
  width: 6in;
  box-sizing: border-box;
}

.template_label_6x4_inch_2 .row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0;
  margin-bottom: 0;
}

.template_label_6x4_inch_2 .print-label-each {
  flex-direction: column;
  height: 4in;
}

.template_label_6x4_inch_2 .print-label-each > div {
  margin: 4px;
}

/* Label general */
.print-label-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 60px;
  padding-bottom: 30px;
}

.print-label-default-margin {
  margin: auto;
}

.print-label-navigator header {
  overflow: auto;
}

.print-label-page-break-render {
  position: relative;
  display: block;
  width: 100%;
  min-width: max-content;
  /* height: 20px; */
  padding: 10px 0;
  text-align: center;
  /* border: 1px dashed #2167D3; */
}

.print-label-page-break {
  /* margin-top: 20px; */
}

.template_A4_1 .print-label-page-break {
  margin-top: 5px;
}

.print-label-each {
  border: 1px solid white;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px 5px;
  box-sizing: border-box;
  /* height: 5cm; */
  overflow: hidden;
}

.print-label-border .print-label-each {
  box-sizing: border-box;
  border: 1px solid black;
}

.print-label-border-radius .print-label-each {
  border-radius: 15px;
}

@media print {
  .print-label-default-margin {
    margin: 0;
  }

  .print-label-horizontal-center {
    margin: auto !important;
  }

  body,
  html {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .print-label-wrapper {
    height: 100%;
    margin: 0;
    padding-bottom: 0;
  }

  .print-label-navigator {
    display: none;
    visibility: hidden;
  }

  .print-label-page-break {
    margin-top: 0;
  }
  .print-label-page-break-render {
    display: none;
  }
}
